.gameCardRegistration {

    form {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        .inputContainer {
            margin: 0 0 5px 0;

            label {
                color: #000;
                text-align: right;
                max-width: 25%;
                width: 100%;
                margin: 0;
                padding: 0 10px 0 0;
                display: inline-block;

                @media (max-width: 767px) {
                    max-width: 23%;
                    font-size: 10px;
                }
            }

            input {
                display: inline-block;
                max-width: 74%;
                width: 100%;
                padding: 0 10px;
                margin: 0 0 8px 0;
                font-size: 12px;
                color: #000;
                font-weight: normal;
            }
            select {
                color: #000;
                text-align: right;
                max-width: 14%;
                margin: 0;
                padding: 0 10px 0 0;
                display: inline-block;
            }
            #numberReg {
                max-width: 59%;
            }

            &.inputContainerSelect {
                input {
                    max-width: 50%;
                }

                select {
                    width: 23%;
                    max-width: 100%;
                    font-size: 12px;
                    padding: 3px 0;
                    border: 1px solid $lightgrey !important;
                }
            }
        }

        .submitBtn {
            width: 100%;
        }

        .formNote {
            text-align: center;
            font-size: 10px;
            font-weight: normal;

            span {
                margin: 5px 0;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}