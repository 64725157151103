.zemmzModal {
    position: fixed;
    display: none;
    width: 100%;
    max-width: 600px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    border: 1px solid;

    &.active {
        display: block;
    }
}