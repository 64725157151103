.gameCardBodyHelp {
    display: none;
    background-color: #fff;
    padding: 40px 40px 60px;

    &.active {
        display: block;
    }

    .helpHeader {
        overflow: hidden;
        margin: 0 0 30px 0;

        h2 {
            font-size: 32px;
            display: inline-block;
        }

        a {
            float: right;
            padding: 5px;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .helpBody {
        font-size: 0;

        .helpItem {
            width: 33%;
            padding: 20px 10px;
            display: inline-block;

            a {
                display: block;
                font-size: 16px;
                padding: 20px;
                color: #000;
                text-align: center;
                border: 2px solid #000;
            }
        }
    }
}