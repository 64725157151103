.bracket {
    padding: 40px;
    background-color: #fff;

    label, form input[type=text], input[type=submit], textarea{
        float: left;
        clear: both;
    }

    textarea{
        height: 250px;
        font-family: arial, sans-serif;
    }

    input[type=submit]{
        background-color: #a2c257;
        border-color: #8ba446;
        color: white;
        cursor: pointer;
        margin: 0;
        width: 500px;
    }

    input:focus, textarea:focus{
        border-color: black;
    }

    #brackets-wrapper, #round-titles-wrapper{
        position: relative;
        margin-top: 20px;
        // float: left;
    }

    #brackets-wrapper{
        top: 70px;
    }

    .round-title{
        height: 30px;
        text-align: center;
        line-height: 30px;
    }

    .round-title, .match-wrapper{
        border: 1px solid #cdc9c9;
        box-sizing: border-box;
        position: absolute;
        width: 150px;
        background-color: #f5f5f5;
    }

    .match-divider{
        width: 100%;
        float: left;
        border-top: 1px solid #cdc9c9;
    }

    .horizontal-connector, .vertical-connector{
        position: absolute;
    }

    .vertical-connector{
        border-left: 3px solid #cdc9c9;
        width: 3px;
    }

    .horizontal-connector{
        border-top: 3px solid #cdc9c9;
        width: 20px;
    }

    .player-wrapper{
        background-color: #f5f5f5;
        box-sizing: border-box;
        padding-left: 5px;
        width: 80%;
    }

    .score{
        background-color: #f0f0f0;
        box-sizing: border-box;
        text-align: center;
        width: 20%;
        border: 0;
        font-size: 16px;
        font-family: arial, sans-serif;
    }

    .player-wrapper, .score{
        float: right!important;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
    }

    #version{
        color: #404040;
        width: 488px;
        text-align: center;
        margin-left: 20px;
    }

    select::-ms-expand{
        display: none;
    }

    @media print{
        form, h1, #version{
            display: none;
        }
        .round-title, .match-wrapper, .player-wrapper{
            border-color: black;
        }
        .match-divider, .vertical-connector, .horizontal-connector, select{
            border-color: black;
        }
    }
    #success{
        background-color: #DFF2BF;
    }
}