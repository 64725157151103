.tournamentSchedule {
    padding: 40px;

    h2 {
        margin: 0 0 50px;
    }

    .scheduleItem {
        font-size: 0;
        margin: 0 0 30px;

        h3 {
            font-size: 16px;
        }

        h4 {
            font-size: 14px;
            margin: 0 0 10px;
        }

        .scheduleMatchItem {
            width: 100%;
            max-width: 16.6%;
            padding: 10px 0;
            text-align: center;
            display: inline-block;

            .roundNb {
                font-size: 14px;
            }

            .roundTime {
                font-size: 14px;
            }
        }
    }
}