.gameCardValidateScore {

    h3 {
        margin: 20px 0;
        font-size: 32px;
        text-align: center;
    }

    form {
        margin: auto;
        width: 100%;
        max-width: 300px;

        .leftContainer {
            width: 50%;
            display: inline-block;

            .inputContainer {
                width: 100%;

                label {
                    width: 60px;
                    margin: 0 5px 0 0;
                    display: inline-block;
                }

                input {
                    width: 50px;
                    padding: 0 5px;
                    display: inline-block;
                }

                .uploadMsg, i.fa-upload {
                    &.hide {
                        display: none;
                    }
                }
            }
        }


        .rightContainer {
            width: 49%;
            display: inline-block;
            vertical-align: top;

            .inputFile {
                position: relative;
                width: 100%;
                padding: 10px;
                margin: 0 auto 10px;
                background-color: #fff;
                border: 2px solid black;

                img {
                    max-width: 150px;
                    max-height: 70px;
                    margin: auto;
                    display: block;
                }

                span {
                    display: inline-block;
                    width: 64%;
                    margin: 0;
                    text-align: center;
                }

                i {
                    display: inline-block;
                    width: 30%;
                    margin: 0;
                    font-size: 32px;
                    color: #000;
                }

                input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .formFooter {

            .submitBtn {
                width: 45%;
                font-size: 14px;
                font-weight: bold;
                vertical-align: top;
            }

            .linkBtn {
                width: 45%;
                float: right;
                text-align: center;
                padding: 9px 10px;
                vertical-align: top;
                display: inline-block;
            }
        }
    }
}