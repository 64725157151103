.tournamentStandings {
    padding: 20px 40px;

    h2 {
        margin: 0 0 30px;
    }

    .standingTable {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        font-size: 0;
        border: 2px solid black;

        .standingHead {
            .standingCol {
                border: none;

                &.standingUser {
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                }
            }
        }

        .standingBody {
            .standingCol {
                border-bottom: none;
            }
        }

        .standingCol {
            text-align: center;
            font-size: 18px;
            line-height: 36px;
            display: inline-block;
            border: 1px solid black;

            &.standingRank {
                width: 25%;
                border-left: none;
            }

            &.standingScore {
                width: 25%;
                border-right: none;
            }

            &.standingUser {
                width: 50%;
            }
        }
    }
}