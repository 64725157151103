#popup-chat-btn {
  a {
    display: none;
    top:50%;
    position:fixed;
    right:-20px;
    z-index: 1000;

    width:80px;
    height:80px;
    background-color: #1370F6;

    color:#fff;
    border-radius:100%;
    line-height:80px;
    text-align:center;
    vertical-align:middle;
    .fa-comment {
      line-height: inherit;
      color:#fff;
    }
  }
}

#popup-chat {
  display: none;
}

.popup-chat {
  position:fixed;
  height:50%;
  top: 30%;
  width:40vh;
  right:0px;
  z-index: 9999;
  background-color: #ffffff;
  margin-right: -40vh;
  padding: 15px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;

  @media (max-width: 767px) {
    width: 85%;
    margin-right: -85%;
    bottom: 20px;
    height: 80%;
    padding: 20px;
    top: initial;
  }

  .chat-area-actions {

    > a {
      padding-left: 2em;

      i {
        color: #000000;
      }
    }
  }

  .chat-area-hdr {
    height: 5%;
    padding-right: 30px;
  }

  .chat-area-list {
    height: 80%;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 767px) {
      height: 75%;
    }
  }

  .chat-area-input {

    overflow: hidden;
    padding-left: 10px;

    .chat_input {
      background-color: lightgrey !important;
      width: 80%;
      min-height: 25px;
      max-height: 35px;
      margin: 0;
      overflow: hidden;
      display: inline-block;
      float: left;
    }

    .btn-chat {
      float: left;
      min-height: 25px;
      max-height: 35px;
      margin: 0;

      .chat-area-send {
        color: white;
      }
    }
  }

}

.base_receive p {
  background: #4bdbe6;
}

.base_sent p {
  background: #e674a8;
}

time {
  font-size: 11px;
  font-style: italic;
}

#login-box {
  margin-top: 20px
}

#chat_box {
  position: fixed;
  top: 10%;
  right: 5%;
  width: 27%;
}

.close-chat {
  margin-top: -17px;
  cursor: pointer;
}

.chat_box {
  margin-right: 25px;
  width: 310px;
}

.chat-area {
  height: 400px;
  overflow-y: scroll;
}

#users li {
  margin-bottom: 5px;
}

#chat-overlay {
  position: fixed;
  right: 0%;
  bottom: 0%;
}

.glyphicon-ok {
  color: #42b7dd;
}

.loader {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}