.gameCardThankYou {

    form {
        max-width: 300px;
        width: 100%;
        margin: auto;

        h3 {
            font-size: 12px;
            margin: 0 auto 20px;
        }

        .thankYouBtn {

            .calendar {
                padding: 10px 20px;
                color: #000;
                background-color: #fff;
                border: 1px solid black;
            }

            .skip {
                padding: 10px 20px;
                color: #fff;
                background-color: #000;
                border: 1px solid black;
            }
        }
    }
}