.gameCardRegister {

    form {
        max-width: 300px;
        width: 100%;
        margin: 100px auto 0;

        .platformId {
            display: none;

            &.show {
                display: block;
            }
        }

        .submitBtn {
            width: 100%;
        }
    }
}