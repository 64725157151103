.gameCardRegistrationValidation {

    form {
        display: table;
        max-width: 300px;
        margin: 0 auto;

        h3 {
            font-size: 12px;
            margin: 0 auto 20px;
        }

        .inputContainer {
            text-align: center;

            input {
                color: #000;
                height: 40px;
                padding: 0;
                margin: 0 5px;
                max-width: 30px;
                text-align: center;
                display: inline-block;
                border: 1px solid black;
            }
        }

        .submitBtn {
            width: 100%;
            display: block;
            margin: 20px auto 10px;
        }

        .resendMail {
            text-align: center;

            a {
                color: #000;
                text-decoration: underline;
            }
        }
    }
}