.generalGameInfo {
    text-align: left;
    color: black;
    width: 30%;
    float: left;
    display: inline-block;
    padding: 30px 50px;
    text-transform: uppercase;

    @media (max-width: 767px) {
        width: 100%;
        float: none;
        padding: 20px;
    }

    h3 {
        font-size: 24px;
    }

    .platform {
        a {
            color: black;
            font-size: 10px;
            margin: 10px 0;
            padding: 3px 10px;
            cursor: context-menu;
            display: inline-block;
            border: 1px solid black;
            background-color: #fff;

            &:hover {
                color: black;
                opacity: 1;
                background-color: #fff;
            }
        }
    }

    .date, .time {

    }

    .gameFormat {
        margin: 10px 0;
    }

    .prisePool, .capacity {
        label {
            margin: 0;
        }
    }
}