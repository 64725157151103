.homeCarousel {
    .owl-dots {
        display: table;
        margin: 10px auto;

        .owl-dot {
            display: inline-block;

            span {
                display: block;
                margin: 0 5px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                border-radius: 50%;
                background-color: $blue;
            }

            &.active {
                span {
                    background-color: $lightblue;
                }
            }
        }
    }
}