.gameCardRegistrationPlatform {

    form {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;

        h3 {
            font-size: 12px;
            margin: 0 auto 20px;
        }

        .inputContainer {

            label {
                color: #000;
                width: 100%;
            }

            input {
                color: #000;
                width: 100%;
            }

            .platformSelect {
                width: 100%;
            }
        }

        .platformId {
            display: none;

            &.show {
                display: block;
            }
        }

        .submitBtn {
            width: 100%;
        }
    }
}