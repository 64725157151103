// Colors:
$lightgrey: #eaeaea;
$grey: #b1b1b1;
$lightGreyOpacity: rgba(117, 117, 117, 0.95);

$pink: #FB8D87;
$gold: #FCB722;
$orange: #FB8730;
$cyan: #3FFFB5;
$blue: #342AD3;
$lightblue: #1370F6;
$darkblue: #00022F;