.gameCardNav {
    position: relative;
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 $lightgrey;

    @media (max-width: 767px) {
        overflow: auto;
        white-space: nowrap;
        display: block;
    }

    &.fixed {
        position: fixed;
        top: 50px;
        left: 0;
    }

    li {
        width: 20%;
        text-align: center;
        display: inline-block;

        @media (max-width: 767px) {
            width: initial;
        }

        a {
            color: #000;
            padding: 10px;
            display: block;
            font-size: 18px;
            text-transform: uppercase;
            background-color: transparent;

            &.active {
                color: #fff;
                background-color: $blue;
            }
        }
    }
}