.formBtn {
    color: #fff;
    width: 100%;
    max-width: 350px;
    padding: 10px;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid $blue;
    background-color: $blue;
    @include transition-all(0.4s);

    &:hover {
        color: $blue;
        background-color: #fff;
        @include transition-all(0.4s);
    }

    @media (max-width: 767px) {
        max-width: 80%;
        margin: auto;
        display: block;
    }
}

.linkBtn {
    color: #000;
    padding: 10px 20px;
    border: 2px solid #000;
    background-color: #fff;
    @include transition-all(0.4s);

    &:hover {
        color: #fff;
        padding: 10px 20px;
        border: 2px solid #000;
        background-color: #000;
        @include transition-all(0.4s);
    }
}