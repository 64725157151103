.gameCard {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: $lightGreyOpacity;

    &.active {
        display: block;
    }

    .gameCardContainer {
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);

        .gameCardHeader {
            padding: 10px 0;
            text-align: left;
            position: relative;

            .gameCardHeaderTitle {
                color: #fff;
                font-size: 32px;
                display: inline-block;
                margin: 0 20px 0 0;
                vertical-align: middle;
            }

            .gameCardHeaderHelp {
                font-size: 18px;
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;

                i {
                    color: #000;
                }
            }

            .gameCardHeaderExit {
                position: absolute;
                right: -8px;
                bottom: -32%;
                z-index: 2;
                padding: 5px;
                width: 30px;
                text-align: center;
                height: 30px;
                border-radius: 50%;
                display: inline-block;
                background-color: lightgray;

                i {
                    font-size: 16px;
                    margin: 0;
                    color: #fff;
                }
            }
        }

        .gameCardBody {
            position: relative;
            padding-top: 200px;
            height: 550px;
            overflow-y: scroll;
            background-color: #fff;

            &.hidden {
                display: none;
            }

            &.scroll {
                overflow-y: scroll;
                max-height: 600px;
            }

            // The game card navigation
            @import "partials/gameCardNav";

            .gameCardImage {
                height: 200px;
                background-color: white;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1;
            }

            .gameCardImage img {
                height: 100%;
            }

            .gameCardBodyContent {
                display: none;
                position: relative;
                z-index: 2;
                background-color: #fff;

                &.active {
                    display: block;
                }

                .gameCardInfo {
                    overflow: hidden;
                    // Remove the arrows from number input
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    // General Game Information
                    @import "partials/gameCardGameInfo";

                    .dynamicGameInfo {
                        float: left;
                        width: 70%;

                        @media (max-width: 767px) {
                            width: 100%;
                            float: none;
                        }

                        .cardSteps {
                            display: table;
                            margin: 10px auto 20px;

                            li {
                                display: inline-block;

                                a {
                                    color: grey;
                                    position: relative;
                                    padding: 0 36px 0 10px;
                                    margin: 0 5px 0 0;

                                    &:before {
                                        content: "";
                                        width: 8px;
                                        height: 8px;
                                        background-color: grey;
                                        display: inline-block;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }

                                    &:after {
                                        content: "";
                                        width: 30px;
                                        position: absolute;
                                        right: 0;
                                        height: 2px;
                                        background-color: grey;
                                        display: inline-block;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }

                                    &.active {
                                        color: $blue;

                                        &:before, &:after {
                                            background-color: $blue;
                                        }
                                    }
                                }

                                &:last-child {
                                    a:after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .dynamicGameInfoContent {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }


                        @import "partials/screens/registration";

                        @import "partials/screens/validation";

                        @import "partials/screens/idRegistration";

                        @import "partials/screens/loggedInRegistration";

                        @import "partials/screens/registrationThanks";

                        @import "partials/screens/match";

                        @import "partials/screens/validateScore";
                    }
                }

                @import "partials/gameCardBracket";

                .errorMessages {
                    display: table;
                    margin: 0 auto 10px;

                    span {
                        display: block;
                        margin: 2px;
                        padding: 0;
                        color: red;
                    }
                }
            }
        }

        .scoreMessageContainer {
            text-align: center;
            margin: 40px 0 0;

            h3 {
                font-size: 24px;
            }
        }

        @import "partials/gameCardHelp";
        @import "partials/gameCardRules";
        @import "partials/gameCardScheduel";
        @import "partials/gameCardStandings";

        .tournamentMessage {

            .tournamentMessageContainer {
                margin: 40px auto 0;

                h3 {
                    font-size: 24px;
                    text-align: center;
                }
            }
        }
    }
}