@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

@mixin transition-all($speed) {
  -webkit-transition: all $speed;
  -moz-transition: all $speed;
  transition: all $speed;
}

@mixin placeholder($color: #999) {
  &:-moz-placeholder            { color: $color; } // Firefox 4-18
  &::-moz-placeholder           { color: $color; } // Firefox 19+
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

