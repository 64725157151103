.gameCardMatch {
    color: #000;
    margin: 40px 0 0 0;

    .kickOff {
        width: 50%;
        float: left;
        text-align: center;

        h3 {
            font-size: 32px;
        }

        h4 {
            font-size: 24px;
        }

        a {
            display: table;
            margin: 50px auto 0;
        }
    }

    .matchInfo {
        width: 50%;
        float: left;

        .nextGame {
            margin: 0 0 20px 0;
        }

        .opponent {
            .opponentInfo {
                width: 59%;
                display: inline-block;
            }

            .opponentReach {
                width: 40%;
                display: inline-block;

                ul {
                    display: table;
                    margin: auto;

                    li {
                        display: inline-block;

                        a {
                            font-size: 20px;
                            padding: 6px;
                            border-radius: 50%;
                            margin: 0 10px 0 0;
                            border: 2px solid black;

                            i {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .nextGame, .opponent {

            h3, h4 {
                font-size: 20px;
            }
        }
    }
}